<template>
  <!--begin::Advance Table Widget 9-->
  <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column pl-2">
        <!-- <span class="card-label font-weight-bolder text-dark">Alla Kategorier</span> -->
      </h3>
      <div class="card-toolbar">
        <div class="d-flex justify-end">
          <memlist-icon-button
            icon="menu-icon flaticon2-plus"
            :text="$t('PAGES.ATTRIBUTES.NEW_CATEGORY')"
            variant="primary"
            @click="create_group_clicked"
          />
        </div>
        
      </div>
    </div>
    
    <div class="card-body pt-0 table-responsive">
      <b-table
        id="stickprov-table"
        :fields="headers"
        :items="items"
        head-variant="light"
        class='table-striped w-100 mh-100'
        sticky-header
      >
        <template #cell(action)='row'>
          <div class='d-flex justify-content-end'>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm mx-3"
              @click.prevent="select_group_clicked(row.item.group_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Write.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
            <a
              href="#"
              class="btn btn-icon btn-light btn-sm"
              @click.prevent="delete_group_clicked(row.item.group_id)"
            >
              <span class="svg-icon svg-icon-md svg-icon-primary">
                <!--begin::Svg Icon-->
                <inline-svg src="/assets/svg/Trash.svg"></inline-svg>
                <!--end::Svg Icon-->
              </span>
            </a>
          </div>
        </template>
      </b-table>
    </div>
  </div>

</template>

<script>
import axios from 'axios';
import { toasts } from '@/core/mixins/toastr-helper.mixin.js';

export default {
  name: 'PropertyGroupTable',
  mixins: [ toasts ],
  props: ['items', 'currentCompanyId'],
  emits: ['create_group_clicked', 'select_group_clicked', 'delete_group_clicked'],
  components: {

  },
  methods: {
    create_group_clicked() {
      this.$emit('create_group_clicked');
    },
    select_group_clicked(group_id) {
      this.$emit('select_group_clicked', group_id);
    },
    delete_group_clicked(group_id) {
      this.$emit('delete_group_clicked', group_id);
    },
    
  },
  data() {
    return {
      headers: [
        {
          key: 'name',
          label: this.$t('PAGES.ATTRIBUTES.CATEGORY'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'priority',
          label: this.$t('MEMBER_PROPERTY.PRIORITY'),
          sortable: true,
          thClass: 'pl-7',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'company.name',
          label: this.$t('COMPANY.COMPANY'),
          sortable: true,
          thClass: 'pl-7 w-100',
          tdClass: 'pl-7 align-middle'
        },
        {
          key: 'action',
          label: '',
          thClass: 'w-110px',
          tdClass: 'align-middle'
        },
      ],
      list: [

      ]
    };
  }
};
</script>
